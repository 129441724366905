.wrapper .gallery {
  --columns-count: 3;
}

@media (--mobile-m) {
  .wrapper .gallery {
    .slide {
      --width: 350px;
    }
  }
}

@media (--mobile) {
  .wrapper .gallery {
    .slide {
      --width: 310px;
    }
  }
}

@media (--mobile-xxs) {
  .wrapper .gallery {
    .slide {
      --width: 260px;
    }
  }
}
